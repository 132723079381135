
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '../styles/global.css';
import '../public/hco_fonts/hco_fonts.css';
import Script from 'next/script';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
function MyApp({ Component, pageProps }) {
    return (<>
      {/* Cookie Information */}
      <Script strategy="afterInteractive" src="https://policy.app.cookieinformation.com/uc.js" id="CookieConsent" type="text/javascript" data-culture="NB"/>
      {/* End Cookie Information */}

      {/* Google Tag Manager */}
      
        <Script id="GTM-NJLSNVRV" strategy="afterInteractive" dangerouslySetInnerHTML={{
            __html: `
                window.addEventListener('CookieInformationConsentGiven', function (event) {
                if (CookieInformation.getConsentGivenFor('cookie_cat_functional') || CookieInformation.getConsentGivenFor('cookie_cat_statistic') || CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-NJLSNVRV');
              }
            }, false);
          `,
        }}/>
      {/* End Google Tag Manager */}
      <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY} scriptProps={{
            async: false,
            defer: false,
            appendTo: 'head',
            nonce: undefined,
        }}>
        <Component {...pageProps}/>
      </GoogleReCaptchaProvider>
    </>);
}
const __Next_Translate__Page__1914f85d89c__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__1914f85d89c__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  